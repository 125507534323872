import {
  Grid,
  Typography,
  withTheme,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import logo from "../assets/logo.png";
import print01 from "../assets/print01.png";
import print02 from "../assets/print02.png";
import print03 from "../assets/print03.png";

const C4 = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* HEADER */}
      <div
        class={"header"}
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "blue",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          ...(matches
            ? { backgroundAttachment: "fixed" }
            : { backgroundPositionX: "right" }),
        }}
      >
        <div style={{ marginTop: "10vh" }}>
          <img src={logo} width={200} />
        </div>
        <div style={{ padding: "50px", maxWidth: 900 }}>
          <Typography variant={"h1"} align={"center"}>
            Uma plataforma para revolucionar o comércio da sua cidade.
          </Typography>
        </div>
      </div>
      {/* CONTENT */}
      <div style={{ maxWidth: 1200, padding: 30 }}>
        {/* INTRO */}
        <div
          style={{
            marginTop: matches ? 100 : 40,
          }}
        >
          <div>
            <Typography variant={"body1"}>
              Câmara Quatro é uma plataforma com soluções para CDL’s (Câmaras de
              Dirigentes Lojistas) em que levamos juntos inovação para o
              comércio local conectando consumidores, empresas e instituições em
              uma plataforma digital que todos ganham.
            </Typography>
            <Typography variant={"body1"}>
              Incentive o comércio e fortaleça instituições locais através de
              compras e doações pela plataforma Câmara Quatro.
            </Typography>
          </div>
        </div>
        {/* INFO */}
        <div>
          <Typography variant={"h2"}>Benefícios para cidade</Typography>
          <Typography variant={"body1"}>
            Fomento ao comércio local.
            <br />
            Melhor relacionamento entre lojistas e consumidores.
            <br />
            Melhor relacionamento da CDL com associados e comunidade.
          </Typography>
        </div>
        <div>
          <Typography variant={"h2"}>Recursos</Typography>
          <Typography variant={"body1"}>
            Site + App + Admin
            <br />
            Relatórios em tempo real
            <br />
            Fluxos administrativos na plataforma
            <br />
            Possibilidades de integração que simplificam a gestão
            <br />
            Fonte de receita com novos serviços digitais para associados
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography variant={"h3"}>Infraestrutura e Segurança</Typography>
            <Typography>
              Desenvolvido sob infraestrutura Google Cloud e com políticas
              rígidas de privacidade o projeto da sua cidade roda de forma
              isolada e está seguro para escalar.
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant={"h3"}>Tecnologia e Design</Typography>
            <Typography>
              Flexibilidade para se adaptar a identidade da cidade e criar valor
              de forma ágil, trabalhamos continuamente melhorando a experiência
              dos usuários.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Typography variant={"h3"}>Consumidores</Typography>
            <Typography variant={"body1"}>
              Usuários do programa instalam o aplicativo e começam a usar em
              instantes.
            </Typography>
            <Typography variant={"body2"}>
              <strong>Gestão da Conta</strong>
              <br />
              Cadastro sem senha com celular
              <br />
              Atualização de dados e endereço
              <br />
              Adicionar foto do perfil
              <br />
              Notificações de status
              <br />
              Histórico de compras
              <br />
              <br />
              <strong>Cashback</strong>
              <br />
              Consultar lojas e instituições participantes
              <br />
              Receber cashback em compras no comércio
              <br />
              Receber troco como cashback
              <br />
              Fazer doações para instituições
              <br />
              Transferir cashback para amigos
              <br />
              Compartilhar perfil através de links e QR Code
              <br />
              Aprovação de transações
              <br />
              Consulta do histórico de transações
              <br />
              Histórico da transação
              <br />
              QR Code e link exclusivo do perfil
              <br />
              Ler QR Code com a câmera e imagens
              <br />
              Adicionar loja ou instituição
              <br />
              <br />
              <strong>Loja CDL</strong>
              <br />
              Visualizar categorias e preços
              <br />
              Consultar produtos e cursos
              <br />
              Informar endereço de recebimento e faturamento
              <br />
              Pagamento na retira, cartão de crédito e boleto
              <br />
              Selecionar endereço de retirada
              <br />
              Efetuar compras
              <br />
              Consultar pedidos e status
            </Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant={"h3"}>Associados</Typography>
            <Typography>
              Empresas, lojas, marcas tem uma gestão descomplicada e assertiva.
            </Typography>
            <Typography variant={"body2"}>
              <strong>Gestão da Conta</strong>
              <br />
              Cadastro online
              <br />
              Atualização de dados e endereço
              <br />
              Imagens da empresa
              <br />
              Adicionar redes sociaisv Informar dados bancários
              <br />
              Notificações de status
              <br />
              Histórico de compras
              <br />
              Adicionar cartão de crédito
              <br />
              <strong>Níveis de acesso</strong>
              <br />
              Adicionar colaboradoresv Administrador, gerente e colaborador
              <br />
              Histórico de modificações
              <br />
              <br />
              <strong>Cashback</strong>
              <br />
              Compra de crédito cashback
              <br />
              Solicitação de saque do cashback
              <br />
              Notas de débito de saques e depósitosv Atualizar o percentual de
              cashback
              <br />
              Dar cashback através de links e QR Code
              <br />
              Receber cashback
              <br />
              Fornecer troco como cashback
              <br />
              Aprovação de transações
              <br />
              Consulta do histórico de transações
              <br />
              Histórico da transação
              <br />
              Extrato analítico diário e mensal
              <br />
              Doações para instituições
              <br />
              QR Code e link exclusivo da empresa
              <br />
              Ler QR Code com a câmera e imagens
            </Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant={"h3"}>Instituições</Typography>
            <Typography>
              Fazer o bem local e transformar também faz parte do projeto.
            </Typography>
            <Typography variant={"body2"}>
              <strong>Gestão da Conta</strong>
              <br />
              Cadastro online
              <br />
              Atualização de dados e endereço
              <br />
              Imagens da instituição
              <br />
              Adicionar redes sociais
              <br />
              Informar dados bancários
              <br />
              Notificações de status
              <br />
              <br />
              <strong>Níveis de acesso</strong>
              <br />
              Adicionar colaboradores
              <br />
              Administrador, gerente e colaborador
              <br />
              <br />
              <strong>Cashback</strong>
              <br />
              Receber doações cashback
              <br />
              Solicitação de saque do cashback
              <br />
              Notas de débito de saques
              <br />
              Aprovação de transações
              <br />
              Consulta do histórico de transações
              <br />
              Histórico da transação
              <br />
              Extrato analítico diário e mensal
              <br />
              QR Code e link exclusivo da instituição
              <br />
              Ler QR Code com a câmera e imagens
            </Typography>
          </Grid>
        </Grid>
        <Typography variant={"h2"}>Cases</Typography>
        <Typography variant={"body1"}>
          CDL BOX A CDL Jaraguá do Sul já começou a transformar o comércio da
          cidade.
        </Typography>
      </div>
      <div style={{ maxWidth: 1440, width: "100%" }}>
        <img src={print01} width={"100%"} />
        <br />
        <img src={print02} width={"100%"} />
        <br />
        <img src={print03} width={"100%"} />
      </div>
      {/* RODAPE */}
      <div
        style={{
          backgroundColor: "#0232b5",
          width: "100%",
          marginTop: "10vh",
        }}
      >
        <div style={{ textAlign: "center", margin: matches ? 100 : 40 }}>
          <Typography variant={"h4"}>
            Transforme a CDL e o comércio da sua cidade!
          </Typography>
        </div>
        <div
          style={{
            margin: matches ? 100 : 40,
            paddingTop: matches ? 100 : 40,
            borderTop: "1px solid #003fef",
          }}
        >
          <Grid container spacing={4}>
            <Grid item sm={6}>
              <img src={logo} width={160} style={{ marginBottom: 20 }} />
              <Typography variant={"subtitle2"}>
                Claryt - Todos os direitos reservados.
                <br />
                DPO - Luann | luann@claryt.com
                <br />
                CNPJ - 37.148.585/0001-00
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Typography variant={"subtitle2"}>
                Somos uma startup de Jaraguá do Sul / SC e temos como propósito
                empoderar realizadores através dos nossos valores: clareza,
                conhecimento e excelência na execução.
              </Typography>
              <Typography variant={"subtitle2"}>
                Nossa missão é unir pessoas, design, processos e tecnologia
                criando modelos de negócio inovadores.
              </Typography>
              <Typography variant={"subtitle2"}>
                Estamos focados na popularização do Aplicativo de marca para
                branding, relacionamento e vendas. Colaboramos também em
                processos de transformação digital com Business Design.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default C4;
