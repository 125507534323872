import "./App.css";
import C4 from "./App/C4";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

function App() {
  const theme = createMuiTheme();
  theme.typography.h1 = {
    fontFamily: "Sora, sans-serif",
    fontWeight: "600",
    color: "#FFF",
    fontSize: "30px",
    lineHeight: 1.4,
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "60px",
    },
  };
  theme.typography.h2 = {
    fontFamily: "Sora, sans-serif",
    fontWeight: "600",
    fontSize: "20px",
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
      marginTop: 40,
      marginBottom: 30,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px",
    },
  };
  theme.typography.h3 = {
    fontFamily: "Sora, sans-serif",
    fontWeight: "600",
    fontSize: "20px",
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      marginTop: 40,
      marginBottom: 30,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "30px",
    },
  };
  theme.typography.h4 = {
    fontFamily: "Sora, sans-serif",
    fontWeight: "600",
    color: "#FFF",
    fontSize: "30px",
    lineHeight: 1.4,
    [theme.breakpoints.up("md")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "40px",
    },
  };
  theme.typography.body1 = {
    fontFamily: "Merriweather, sans-serif",
    fontWeight: "400",
    color: "#666666",
    fontSize: "16px",
    lineHeight: 1.4,
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
    },
  };
  theme.typography.body2 = {
    fontFamily: "Merriweather, sans-serif",
    fontWeight: "400",
    color: "#000",
    fontSize: "14px",
    lineHeight: 1.4,
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px",
    },
  };
  theme.typography.subtitle2 = {
    fontFamily: "Merriweather, sans-serif",
    fontWeight: "400",
    color: "#FFF",
    fontSize: "14px",
    lineHeight: 1.4,
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "14px",
    },
  };
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <C4 />
      </ThemeProvider>
    </div>
  );
}

export default App;
